import React from 'react';
// import { useMsal } from "@azure/msal-react";
import { Link } from '@fluentui/react';

const Parking = () => {
    // const { instance } = useMsal();

    // instance.logout();

    return (
        // <div>ออกจากระบบ เรียบร้อยแล้ว!</div>
        <div>
            <center>
                <br/>
                <br/>
                <h1>ระบบคนขายเอกสาร Leceipt</h1>
                <br/>
                <h2>รายละเอียดการสมัครเป็นคนขายเอกสาร Leceipt (Retailer) <Link href="https://www.leceipt.com/partners" underline>คลิกที่นี่</Link></h2>
                <br/>
                {/* <h1>หากท่านต้องการสร้างเอกสาร e-Tax Invoice & e-Receipt คลิกที่ปุ่มข้างล่างนี้</h1> */}
            </center>
        </div>
    );
}

export default Parking;