//import { HighContrastSelectorWhite } from '@fluentui/react';
import React, { useState, useEffect, useRef } from 'react';
//import { withRouter } from 'react-router-dom';
//import { CommandBar, ICommandBarItemProps, IButtonProps } from '@fluentui/react';
import { 
    Persona, 
    PersonaInitialsColor, 
    PersonaSize, 
    createTheme,
    Customizer,  
    CommandButton,
    IconButton,
    Stack
} from '@fluentui/react';

//import Auth from './adapters/Auth-test01';
import { useLocation, useHistory } from "react-router-dom";

import axios from 'axios';

import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../authConfig";
import { profileEditingRequest, passwordResetRequest } from "../../authConfig";

import { ActionButton } from '@fluentui/react/lib/Button';

import * as signalR from '@microsoft/signalr';

const API_URL = process.env.REACT_APP_API_URL;

const headerStyles = {    
        //width: '208',
        //height: '10vh',
        //width: 185,
        height: 70,
        width: '100%',
        //minWidth: '1377px',
        //width: '100%',
        //width: '100vw',
        //overflow: 'auto',
        //boxSizing: 'border-box',
        //border: '1px solid #eee',
        //overflowY: 'auto',
        //paddingTop: '10vh',    
        //background: '#FFFFFF',
        //background: '#106ebe',
        background: '#0078D4',
        //background: 'green',
        border: 0,
        paddingLeft: '12px',
        //paddingTop: '1%',
        paddingTop: '15px',
        margin: 0,
        color: '#FFFFFF',
        fontSize: 26,
        
}

// For color persona text to white
const HeaderTheme = createTheme({
    palette: {
      "themePrimary": "#0078d4",
      "themeLighterAlt": "#eff6fc",
      "themeLighter": "#deecf9",
      "themeLight": "#c7e0f4",
      "themeTertiary": "#71afe5",
      "themeSecondary": "#2b88d8",
      "themeDarkAlt": "#106ebe",
      "themeDark": "#005a9e",
      "themeDarker": "#004578",
      "neutralLighterAlt": "#0b0b0b",
      "neutralLighter": "#151515",
      "neutralLight": "#252525",
      "neutralQuaternaryAlt": "#2f2f2f",
      "neutralQuaternary": "#373737",
      "neutralTertiaryAlt": "#595959",
      "neutralTertiary": "#c8c8c8",
      "neutralSecondary": "#d0d0d0",
      "neutralPrimaryAlt": "#dadada",
      "neutralPrimary": "#ffffff",
      "neutralDark": "#f4f4f4",
      "black": "#f8f8f8",
      "white": "#000000",
      "primaryBackground": "#000000",
      "primaryText": "#ffffff",
      "bodyBackground": "#000000",
      "bodyText": "#ffffff",
      "disabledBackground": "#151515",
      "disabledText": "#595959"
    }
});



/* 
const userInformation = () => {
    const auth = new Auth();
    if (auth.isLoggedIn()) {
        //console.log(auth.currentUser());
        given_name = JSON.parse(auth.currentUser()).given_name;
        //console.log(given_name);
    }
}
 */


const Header = (props) => {
    const history = useHistory();

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [companyName, setCompanyName] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [coins, setCoins] = useState(0);
    const [sms, setSms] = useState(0);
    const [billingsType, setBillingsType] = useState('prepaid');
    const billingsTypeRef = useRef();

    const [command, setCommand] = useState('');

    const { accountData } = props;
    const { companyData } = props;

    const menuProps = {
        items: [   
            {
                name: 'ผู้ใช้งาน',
                url: '',
                key: 'user-profiles',
                iconProps: {
                    iconName: 'PlayerSettings',
                    styles: {
                        root: {
                            fontSize: 20,
                            color: '#106ebe',
                        }
                    }
                },
                onClick: (event, element) => {
                    //console.log('user-profiles!');
                    console.log(element.key);
                    setCommand(element.key);
                }
                
            },                    
            {
                name: 'เปลี่ยนรหัสผ่าน',
                url: '',
                key: 'password-reset',
                iconProps: {
                    iconName: 'Permissions',
                    styles: {
                        root: {
                            fontSize: 20,
                            color: '#106ebe',
                        }
                    }
                },
                onClick: (event, element) => {
                    //console.log('user-profiles!');
                    console.log(element.key);
                    setCommand(element.key);
                }
            },   
                            
            {
        
                name: 'ออกจากระบบ',
                url: '',
                key: 'logout',        
                iconProps: {
                    iconName: 'Leave',
                    styles: {
                        root: {
                            fontSize: 20,
                            color: '#106ebe',
                        }
                    }
                },
                onClick: (event, element) => {
                    //console.log('user-profiles!');
                    console.log(element.key);
                    setCommand(element.key);
                }
            }
        ]
    /* 
        items: [
          {
            key: 'emailMessage',
            text: 'Email message',
            iconProps: { iconName: 'Mail' },
          },
          {
            key: 'calendarEvent',
            text: 'Calendar event',
            iconProps: { iconName: 'Calendar' },
          },
        ],
        */ 
        // By default, the menu will be focused when it opens. Uncomment the next line to prevent this.
        // shouldFocusOnMount: false
      };


    //let given_name = '';
    //initializeIcons();
    //userInformation();
  /*   const insertUser = () => {
        console.log('Insert user!');
    } */

    useEffect(() => {
        if (command === 'logout') {

            let confirmLogout = window.confirm('กรุณายืนยันการออกจากระบบ');

            if (confirmLogout) {

                instance.logout();

            } else {
                setCommand('');
            }

        } else if (command === 'user-profiles') {

            instance.loginRedirect(profileEditingRequest);

            //instance.acquireTokenRedirect(profileEditingRequest);
/* 
            history.push({
                //pathname: '/etax/signatures/register/companies',
                pathname: '/profiles/update',
                //search: '?update=true',  // query string
                state: {}
            })
 */
        } else if (command === 'password-reset') {

            instance.loginRedirect(passwordResetRequest);

        }  
    }, [command]);

    useEffect(() => {
        console.log('userEffect Call!');

        if (accountData && accountData.profiles.name) {
            setName(accountData.profiles.name);
            setEmail(accountData.profiles.emails[0]);
        }

        if (accountData && accountData.profiles.name) {
            // setCoins(accountData.balances.coins.credits);
            setSms(accountData.balances.sms.credits);
        }

        if (companyData && companyData.name) {
            setCompanyName(companyData.name + ' (Retailer)');
        } else {
            setCompanyName('กรุณาตั้งค่าข้อมูลคนขายเอกสาร Leceipt (Retailer)...');
        }    

        if (accountData && accountData.billings && (accountData.billings.type === 'subscription')) {
            setBillingsType('subscription');

            instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then((tokenResponse) => {
                //Check documents spending limit
                axios
                .get(API_URL + '/subscriptions', {
                    headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    },
                })
                .then(
                    (response) => {
                    console.log('Subscriptions data: ', response);

                    if (response.data) {
                        if (response.data && response.data.documents) {
                            console.log(
                                'Subscription etax documents: ',
                                response.data.documents
                            );

                            let _subscriptionDocuments = response.data.documents;

                            let _credits = (_subscriptionDocuments.limit + _subscriptionDocuments.topup) - _subscriptionDocuments.spent

                            setCoins(_credits);


                        }
                    }
                    },
                    (error) => {
                    console.log(error);
                    }
                );
                
            })
            .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
            });
        } else {
            if (billingsType === 'prepaid') {
                setCoins(accountData.balances.coins.credits);
            }
        }

        const accountsConnection = new signalR.HubConnectionBuilder()
            .withUrl('https://leceipt.azurewebsites.net/api/OnAccountsChanged', {
                // skipNegotiation: true,
                // transport: signalR.HttpTransportType.WebSockets
            //   })
            // .withUrl('http://localhost:7071/api/OnAccountsChanged', {
            // , {
            // .withUrl('https://leceipt.azurewebsites.net/api/OnDocumentsChanged', {
                headers: {
                    oid: accountData.oid             
                },
          }
            )
            .configureLogging(signalR.LogLevel.Information)
            .build();

        accountsConnection.on('accountUpdated', function (data) {
          console.log('SignalR push accountUpdated!!');
          console.log(data);
          console.log('Billings type: ', billingsTypeRef.current);
          

            if (data && data.billings && data.billings.type && (data.billings.type === 'subscription')) {
                console.log('Billings type data: ', data.billings.type);
                setBillingsType('subscription');
                setSms(data.balances.sms.credits);
            } else {
                console.log('Set billings type to prepaid');
                setBillingsType('prepaid');
                setSms(data.balances.sms.credits);
                setCoins(data.balances.coins.credits);
            }
         
        });

        async function accountsStart() {
          try {
            await accountsConnection.start();
            console.log('SignalR Accounts Connected.');
          } catch (err) {
            console.log(err);
            setTimeout(accountsStart, 5000);
          }
        }

        accountsConnection.onclose(accountsStart);

        // Start the connection.
        accountsStart();

        const subscriptionsConnection = new signalR.HubConnectionBuilder()
            .withUrl('https://leceipt.azurewebsites.net/api/OnSubscriptionsChanged', {
                headers: {
                    oid: accountData.oid             
                },
          }
            )
            .configureLogging(signalR.LogLevel.Information)
            .build();

        subscriptionsConnection.on('subscriptionUpdated', function (data) {
          console.log('SignalR push subscriptionUpdated!!');
          console.log(data);
          console.log('Billings type: ', billingsTypeRef.current);

            if (data && (billingsTypeRef.current === 'subscription')) {
                if (data && data.documents) {
                    console.log(
                        'Subscription etax documents: ',
                        data.documents
                    );

                    let _subscriptionDocuments = data.documents;

                    let _credits = (_subscriptionDocuments.limit + _subscriptionDocuments.topup) - _subscriptionDocuments.spent

                    setCoins(_credits);

                }
            }

            
         
        });

        async function subscriptionsStart() {
          try {
            await subscriptionsConnection.start();
            console.log('SignalR Subscriptinos Connected.');
          } catch (err) {
            console.log(err);
            setTimeout(subscriptionsStart, 5000);
          }
        }

        subscriptionsConnection.onclose(subscriptionsStart);

        // Start the connection.
        subscriptionsStart();


    }, []);

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (    

        <Stack horizontal style={headerStyles}> 

        
            <Stack vertical style={{ width: '310px' }}>
                <Stack horizontal horizontalAlign="space-between">
                    {/* <IconButton iconProps={{ iconName: 'GlobalNavButton' }} title="ย่อ/ขยายเมนู" checked={true} /> */}
                    <ActionButton onClick={() => {
                            history.push({
                                pathname: '/',
                                //search: '?update=true',  // query string
                                state: {}
                            });
                        }}>
                        <img src="/images/leceipt-logo.png" width="200px"/>
                        
                    </ActionButton>
                </Stack>
            </Stack>
          
            <Stack vertical style={{ width: '100%', paddingLeft: '50px', background: '#0078D4', margin: 0 }}>
                <Stack horizontal horizontalAlign="space-between" disableShrink={true}  >
                    <Stack vertical >
                        <span style={{ marginTop: '2px' }}>
                            {companyName}
                        </span>
                    </Stack>
                
                    <Stack horizontal horizontalAlign="end" disableShrink={true} style={{width: '500px'}}>
                        
                        <span 
                            onClick={() => {
                                history.push({
                                    pathname: '/billings',
                                    //search: '?update=true',  // query string
                                    state: {}
                                });
                            }}  
                            style={{ fontSize: '24px', marginTop: '5px', cursor: 'pointer' }}>
                            เอกสาร: {numberWithCommas(coins)} | SMS: {numberWithCommas(sms)}&nbsp;
                        </span>
                    
                
                    {/* {accountData.profiles.name} */}

                    <CommandButton menuProps={menuProps} style={{ paddingRight: '2%' }}>
                        <Customizer settings={{ theme: HeaderTheme }}>
                            <Persona secondaryText={name} initialsColor={PersonaInitialsColor.darkGreen} initialsTextColor="white" text={email} size={PersonaSize.size40} />
                        </Customizer>
                    </CommandButton>

                    </Stack>

                </Stack>
            </Stack>


        </Stack>
    );
}

export default Header;